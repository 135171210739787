import React from "react";
import "./MiddleCard.css";
import { ReactComponent as ArrowRight } from "../../assets/img/svg/arrow-right.svg";
import { Link } from "react-router-dom";

type TMiddleCard = {
  imgSrc: string;
  imgAlt: string;
  title: string;
  subtitle: string;
  text: string;
  slug: string;
};

const MiddleCard = ({
  imgSrc,
  imgAlt,
  title,
  subtitle,
  text,
  slug,
}: TMiddleCard) => {
  return (
    <Link to={`/article/${slug}`} className="article-center-container">
      <div className="article-center-image-container">
        <img src={imgSrc} alt={imgAlt} height={351} width={550} />
        <div className="article-center-frame" />
      </div>
      <div className="line-text-container">
        <div className="line-container">
          <div className="line" />
          <h5>{title}</h5>
        </div>
        <div className="h2-container">
          <h2>{subtitle}</h2>
        </div>
        <div className="article-center-text-button-container">
          <p className="regular">{text}</p>
        </div>
      </div>
      <button className="secondary">
        Lire
        <ArrowRight className="arrow-right" />
      </button>
    </Link>
  );
};

export default MiddleCard;

import React from "react";
import "./SideCard.css";
import { ReactComponent as ArrowRight } from "../../assets/img/svg/arrow-right.svg";
import { Link } from "react-router-dom";

type TSideCard = {
  imgSrc: string;
  imgAlt: string;
  title: string;
  subtitle: string;
  text: string;
  slug: string;
};

const SideCard = ({
  imgSrc,
  imgAlt,
  title,
  subtitle,
  text,
  slug,
}: TSideCard) => {
  return (
    <Link to={`/article/${slug}`} className="article-left-container">
      <div className="article-left-image-container">
        <img src={imgSrc} alt={imgAlt} height={204} width={250} />
        <div className="article-left-frame" />
      </div>
      <div className="line-text-container">
        <div className="line-container">
          <div className="line" />
          <h5>{title}</h5>
        </div>
        <div className="h2-container">
          <h2>{subtitle}</h2>
        </div>
        <p className="regular" dangerouslySetInnerHTML={{ __html: text }}></p>
      </div>

      <button className="secondary">
        Lire
        <ArrowRight className="arrow-right" />
      </button>
    </Link>
  );
};

export default SideCard;

import React, {useState} from "react";
import "./Contact.css";
import ContactHero from "../../assets/img/contact_hello.png";
import ContactForm from "../../components/ContactForm/ContactForm";
import Presentation from "../../components/Presentation/Presentation";

const Contact = (props: any) => {
    const [isMessageSent, setIsMessageSent] = useState(false);

    const messageHasBeenSent = () => {
        setIsMessageSent(true);
    };

    return (
        <>
            <div className="container">
                <div className="hero">
                    <div className="hero-content-container">
                        <div className="hero-content">
                            <div>
                                <div className="line-container">
                                    <div className="line"/>
                                    <h5>Me contacter</h5>
                                </div>
                                <h1>Contactez-moi!</h1>
                            </div>
                            <div className="h4-container">
                                <p className="regular">
                                    Je vous invite à remplir le formulaire de contact ci-dessous. <br />Je vous réponds au
                                    plus vite !
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="hero-image">
                        <img src={ContactHero} alt="Hello" height={630}/>
                    </div>
                </div>

                <div className="contact-page">
                    <ContactForm currentLocale={props.currentLocale}/>
                </div>
            </div>
            <Presentation/>
        </>
    );
};

export default Contact;

import React from "react";
import "./Bio.css";
import PresentationHero from "../../assets/img/Pix.png";
import {Link} from "react-router-dom";
import SEO from "../../helpers/SEO";

const Bio = () => {
    return (
        <div className="container">
            <div className="hero">
                <div className="hero-content-container">
                    <SEO title={"À propos"} seoTitle={"À propos"}
                         seoDescription={" Je suis traducteur en profession libérale. Je traduis des articles de presse, de la littérature et différents types de textes politiques et différents types de textes politiques et diplomatiques."}
                         seoBanner={PresentationHero}/>
                    <div className="hero-content">
                        <div>
                            <div className="line-container">
                                <div className="line"/>
                                <h5>À propos</h5>
                            </div>
                            <h1>Guillaume Deneufbourg</h1>
                        </div>
                        <div className="h4-container">
                            <p className="regular">
                                Je suis traducteur en profession libérale. Je traduis des articles de presse, de la
                                littérature et différents types de documents, rapports et allocutions politiques et
                                diplomatiques. Vous trouverez plus de détails sur mon parcours dans la biographie
                                ci-dessous.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="hero-image">
                    <img
                        src={PresentationHero}
                        alt="Guillaume Deneufbourg"
                        height={630}
                    />
                </div>
            </div>

            <div className="bio-content">
                <div>
                    <h3>Mon parcours</h3>
                    <p className="regular">
                        Traducteur du néerlandais au français et de l’anglais au français depuis 2002, j’ai traduit dans
                        une foule de domaines depuis mes débuts dans la traduction, de la culture (musées, expos) aux
                        institutions internationales, en passant par le militaire, le tourisme, le sport et même la
                        dentisterie. Au fil du temps, j’ai progressivement recentré ma pratique et me consacre
                        aujourd’hui exclusivement à deux grands domaines :
                    </p>
                    <ul>
                        <li>
                            <p className="regular">
                                Rédactionnel : presse/journalisme, littérature (fiction et non-fiction), arts et
                                culture.
                            </p>
                        </li>
                        <li>
                            <p className="regular">
                                Diplomatie et affaires gouvernementales : institutions (inter)nationales, politique,
                                droit international.
                            </p>
                        </li>

                    </ul>
                    <p className="regular">
                        Je traduis des livres de fiction et de non-fiction et suis accrédité par la
                        Fondation néerlandaise des lettres et <a
                        href={"https://www.literatuurvlaanderen.be/"} target="_blank"> Literatuur Vlaanderen</a>. Je
                        traduis aussi de la poésie,
                        des pièces de théâtre et pour divers magazines (littéraires). Je suis également coordinateur des
                        traductions pour le site d’actualités <a href={"http://www.daardaar.be/"}
                                                                 target="_blank">DaarDaar</a> depuis 2015.
                    </p>
                    <br/>
                    <p className="regular">
                        Dans le domaine de la diplomatie et de la politique, je travaille comme traducteur contractuel
                        pour le Parlement fédéral belge, pour les Nations Unies et pour l’administration américaine
                        (département d’État, US Air Force).
                    </p>
                    <br/>

                    <p className="regular">
                        Outre un Master en traduction obtenu à l’<a href={"https://web.umons.ac.be/fti-eii/fr/"}
                                                                    target="_blank">Université de Mons</a> (Belgique),
                        je suis titulaire d’un
                        Master de spécialisation en Sciences du langage et Traductologie et d’un certificat de formation
                        à la recherche (École doctorale). J’ai suivi une formation en traduction littéraire au Centre
                        européen de traduction littéraire (Bruxelles) et participé à une multitude d’ateliers de
                        traduction à Amsterdam, Anvers et Paris.
                    </p>
                    <br/>

                    <p className="regular">
                        En plus d’être un traducteur en exercice, je suis également enseignant. Passionné de
                        linguistique et de traductologie, je mène des recherches dans ce domaine à l’Université de Mons,
                        où j’enseigne également la traduction. Je suis également intervenant à l’<a
                        href={'https://master-traduction.univ-lille.fr/'} target="_blank">Université de Lille</a> {" "}
                        depuis 2011. J’ai publié des articles, scientifiques et de vulgarisation, et j’ai donné pas mal
                        de conférences et d’exposés en Europe et aux États-Unis. Vous retrouverez sur <a
                        href={"https://staff.umons.ac.be/guillaume.deneufbourg/pubsfr.html"} target="_blank">cette
                        page</a> une
                        liste non exhaustive de mes publications et interventions, et des cours dont je suis titulaire.
                    </p>
                    <br/>

                    <p className="regular">
                        En 2017, j’ai été sélectionné pour le Prix Révélation de Traduction de la Société des Gens de
                        Lettres pour ma traduction intitulée « N’écrire pour personne » du recueil de nouvelles
                        poétiques « Belangrijk is dat ik niet aan lezers denk », de l’écrivain néerlandais A.L.
                        Snijders. En 2019, j’ai remporté le Premier prix <a
                        href={"https://www.un.org/dgacm/fr/content/st-jerome-translation-contest"}
                        target="_blank">Saint-Jérôme</a> des Nations Unies. J’ai décroché la deuxième place de ce même
                        concours l’année suivante, en 2020. En 2021, ma traduction du roman historique « Bleu de Delft
                        », de l’autrice néerlandaise Simone van der Vlugt, a été sélectionnée pour le Prix littéraire de
                        l’Euregio.
                    </p>
                    <br/>

                    <p className="regular">
                        Je suis également impliqué dans le secteur associatif depuis 2010, où je m’efforce de défendre
                        les intérêts de ma profession et lui donner la visibilité qu’elle mérite. Je suis membre du
                        Conseil directeur de la <a href={"http://www.translators.be/"} target="_blank">Chambre belge des
                        traducteurs et
                        interprètes</a>, dont j’ai assuré la
                        présidence de 2017 à 2021. Depuis mai 2022, je représente les traducteurs, traductrices et
                        interprètes au niveau mondial en tant que membre du Conseil directeur de la FIT, la <a
                        href={"https://en.fit-ift.org/"} target="_blank">Fédération
                        internationale des traducteurs</a>.
                    </p>
                    <br/>

                    <p className="regular">
                        Je suis également (simple) membre de diverses associations, dont l’<a
                        href={"https://atlf.org/repertoire-des-traducteurs/deneufbourg/"} target="_blank">Association
                        des traducteurs
                        littéraires de France</a> (ATLF), la <a href={"https://www.sft.fr/fr"} target="_blank">Société
                        française des
                        traducteurs</a> (SFT), l’
                        <a href={"https://www.atanet.org/"} target="_blank">American Translators Association</a>{" "}
                        (ATA), la{" "}
                        <a href={"http://societefrancaisedetraductologie-soft.fr/"} target="_blank">Société
                            française de traductologie</a> (SoFT) et, à titre plus personnel, de{" "}
                        <a href={"https://www.mensa.org/"} target="_blank">Mensa International</a>.
                    </p>
                    <br/>

                    <p className="regular">
                        Pour suivre mon actualité, je vous invite également à me suivre sur les réseaux sociaux : {" "}
                        <a href={"https://www.facebook.com/GuillaumeDeneufbourg/"} target="_blank">Facebook</a>, <a
                        href={"https://www.linkedin.com/in/guillaumedeneufbourg/"} target="_blank">LinkedIn</a>, <a
                        href={"https://twitter.com/GDeneufbourg"} target="_blank">Twitter</a>. Vous pouvez aussi me
                        contacter via la page <Link to={"/contact"}>Me Contacter</Link>
                    </p>
                    <br/>

                </div>
            </div>

            <div className="bio-buttons-container">
                <Link to={"/"}>
                    <button className="primary-small">Mes articles</button>
                </Link>
                <Link to={"/portfolio"}>
                    <button className="primary-small">Mes traductions</button>
                </Link>
                {/*<Link to={"/grow"}>*/}
                {/*  <button className="primary-small">Grow together</button>*/}
                {/*</Link>*/}
            </div>
        </div>
    );
};

export default Bio;

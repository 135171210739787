import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";

const LINKS = [
  { id: 0, to: "/", label: "Mes articles" },
  { id: 1, to: "/bio", label: "Ma bio" },
  { id: 2, to: "/portfolio", label: "Mon portfolio" },
  // { id: 3, to: "/grow", label: "Grow" },
  { id: 4, to: "/contact", label: "Me contacter" },
  { id: 5, to: "/disclaimer", label: "Mentions légales", hidden: true },
];

const Navbar = () => {
  const { pathname } = useLocation();
  const [selectedLinkIndex, setSelectedLinkIndex] = useState<number>();
  const [isHamburgerClicked, setIsHamburgerClicked] = useState(false);

  const handleSelectedLink = (event: any) => {
    const linkIndex: number = parseInt(
      event.currentTarget.getAttribute("data-link")
    );
    setSelectedLinkIndex(linkIndex);
  };

  const handleHamburgerOnClick = () => {
    setIsHamburgerClicked((prevState) => !prevState);
  };

  useEffect(() => {
    const path = LINKS.find((link) => link.to === pathname);

    if (path) {
      setIsHamburgerClicked(() => false);
      setSelectedLinkIndex(() => path.id);
    }
  }, [pathname]);

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (event.target.closest(".navbar-center-hamburger")) return;
      setIsHamburgerClicked(() => false);
    };

    document.addEventListener("click", handleOutsideClick);

    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  return (
    <div className="navbar-container">
      <nav className="navbar-center">
        <Link className="navbar-center-logo" to="/">
          <div className="logo-responsive" />
        </Link>
        <div className="navbar-center-links">
          {LINKS.map((link) => {
            if (link.hidden) return null;
            return (
              <Link to={link.to} key={link.id}>
                <div
                  className={`navbar-center-link ${
                    selectedLinkIndex === link.id ? "selected" : ""
                  }`}
                  onClick={handleSelectedLink}
                  data-link={link.id}
                >
                  <div className={`caption-regular`}>{link.label}</div>
                  <div className={`border-bottom`} />
                </div>
              </Link>
            );
          })}
        </div>
        <div className="navbar-center-hamburger-container">
          <div
            className={`mobile-navlinks ${!isHamburgerClicked ? "hidden" : ""}`}
          >
            {LINKS.map((link) => {
              if (link.hidden) return null;
              return (
                <Link to={link.to} key={link.id}>
                  <div
                    className={`navbar-center-link ${
                      selectedLinkIndex === link.id ? "selected" : ""
                    }`}
                    onClick={handleSelectedLink}
                    data-link={link.id}
                  >
                    <h4>{link.label}</h4>
                    <div className={`border-bottom`} />
                  </div>
                </Link>
              );
            })}
          </div>

          <div
            className={`navbar-center-hamburger ${
              isHamburgerClicked ? "change" : ""
            }`}
            onClick={handleHamburgerOnClick}
          >
            <div className="bar1" />
            <div className="bar2" />
            <div className="bar3" />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;

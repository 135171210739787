import React, {useEffect, useState} from "react";
import "./App.css";
import ScrollToTop from "./helpers/ScrollToTop";
import Navbar from "./components/Navbar/Navbar";
import Home from "./views/Home/Home";
import Footer from "./components/Footer/Footer";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Bio from "./views/Bio/Bio";
import Portfolio from "./views/Portfolio/Portfolio";
import Contact from "./views/Contact/Contact";
import Article from "./views/Article/Article";
import {IntlProvider} from "react-intl";
import frFRMessages from "locales/fr-FR.json";
import nlNLMessages from "locales/nl-NL.json";
import enUSMessages from "locales/en-US.json";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import Modal from "react-modal";
import Disclaimer from "./views/Disclaimer/Disclaimer";
import {HelmetProvider} from "react-helmet-async";
import Config from "./utils/Config";
import axios from "axios";
import {BlogsContext} from "./contexts/BlogsContext";

const LANGUAGES: any = {
    fr: frFRMessages,
    nl: nlNLMessages,
    en: enUSMessages,
};

Modal.setAppElement("#root");

const App = () => {
    const [locale, setLocale] = useState<string>("fr");
    const [blogs, setBlogs] = useState([]);

    const changeLanguage = (locale: string) => {
        setLocale(locale);
    };

    useEffect(() => {
        axios
            .get(`${Config.apis.s3}/Lists/Blogs/listBlogs`, {
                // query URL without using browser cache
                headers: {
                    "Cache-Control": "no-cache",
                    Pragma: "no-cache",
                    Expires: "0",
                },
            })
            .then((blogs) => {
                setBlogs((prevState: Array<any>) => {
                    return blogs.data;
                });
            });
    }, []);

    const blogsContext = {
        blogs: blogs,
        setBlogs: setBlogs
    }
    const helmetContext = {};

    return (
        <BrowserRouter>
            <ScrollToTop/>
            <IntlProvider
                locale={locale}
                defaultLocale={locale}
                messages={LANGUAGES[locale]}
            >
                <GoogleReCaptchaProvider
                    reCaptchaKey="6LcP3OsiAAAAAHNOvMoDVtr1aPokPbQwajvU6lqP"
                    language={locale ?? "fr"}
                ><HelmetProvider context={helmetContext}>
                    <BlogsContext.Provider value={blogsContext}>
                    <Navbar/>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/bio" element={<Bio/>}/>
                        <Route path="/article/:slug" element={<Article/>}/>
                        <Route path="/portfolio" element={<Portfolio/>}/>
                        {/*<Route path='/grow' element={<Grow />} />*/}
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="/disclaimer" element={<Disclaimer/>}/>
                        <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
                    <Footer/>
                </BlogsContext.Provider>
                </HelmetProvider>
                </GoogleReCaptchaProvider>
            </IntlProvider>
        </BrowserRouter>
    );
};

export default App;

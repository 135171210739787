import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Presentation from "../../components/Presentation/Presentation";
import axios from "axios";
import Config from "../../utils/Config";
import aggregateProperties from "../../aggregator/PropertiesAggregator";
import BlogComponent from "../../components/BlogComponents/BlogComponent";
import SideCard from "../../components/SideCard/SideCard";
import { Helmet } from "react-helmet";
import SEO from "../../helpers/SEO";
import PresentationHero from "../../assets/img/Pix.png";

const Article = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState<any>(null);
  const [relatedArticles, setRelatedArticles] = useState<any>(null);

  const loadData = () => {
    Promise.all([
      axios.get(`${Config.apis.s3}/Blogs/${slug}`, {
        // query URL without using browser cache
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }),
      axios.get(`${Config.apis.s3}/Lists/Blogs/listBlogs`, {
        // query URL without using browser cache
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }),
    ])
      .then((responses) => {
        const articleResponse: any = responses[0].data;
        articleResponse.properties = aggregateProperties(
          articleResponse.properties
        );
        articleResponse.components = articleResponse.components.map(
          (component: any) => {
            component.properties = aggregateProperties(component.properties);
            return component;
          }
        );
        let relatedArticles: Array<any> = [];
        responses[1].data.forEach((articleRsp: any) => {
          articleRsp.properties = aggregateProperties(articleRsp.properties);
          if (
            articleRsp.properties.category[0].value ===
              articleResponse.properties.category[0].value &&
            articleRsp._id !== responses[0].data._id
          ) {
            relatedArticles.push(articleRsp);
          }
        });
        setRelatedArticles(() => {
          return relatedArticles;
        });
        setArticle((prevState: any) => {
          return articleResponse;
        });
      })
      .catch(() => {
        navigate("/");
      });
  };
  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    loadData();
  }, [slug]);

  const hero = () => {
    return (
      <div className="article-hero">
          <SEO title={article.properties.title[0].value} seoTitle={article.properties.title[0].value} seoDescription={article.properties.textHomePreview[0].value} seoBanner={article.properties.imageHomePreview[0].value}/>
        <div className="article-hero-text-container">
          <div className="line-container">
            <div className="line" />
            <h5>{article.properties.category[0].value}</h5>
          </div>
          <h2>{article.properties.title[0].value}</h2>
          <p
            className="regular"
            dangerouslySetInnerHTML={{
              __html: article.properties.text[0].value,
            }}
          />
        </div>
        <div className="article-image">
          <img
            src={article.properties.image[0].value}
            alt={article.properties.image[0].settings.alt}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {article && hero()}
      <div className="container">
        <div className="article-content-container">
          {article &&
            article.components.map((component: any) => {
              return (
                <BlogComponent
                  component={component.name}
                  properties={component.properties}
                  key={component._id}
                />
              );
            })}
        </div>
      </div>
      {relatedArticles && relatedArticles.length > 0 && (
        <>
          <div className="related-articles-container">
            <div className="related-articles-title">
              <h3>Articles en lien</h3>
            </div>
          </div>
          <div className="container">
            <div className="related-articles-content">
              {relatedArticles.map((relatedArticle: any, index: any) => (
                <div className="related-article" key={index}>
                  <SideCard
                    imgSrc={relatedArticle.properties.imageHomePreview[0].value}
                    imgAlt={
                      relatedArticle.properties.imageHomePreview[0].settings.alt
                    }
                    title={relatedArticle.properties.category[0].value}
                    subtitle={relatedArticle.properties.title[0].value}
                    text={relatedArticle.properties.textHomePreview[0].value}
                    slug={relatedArticle.slug}
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <Presentation />
    </>
  );
};

export default Article;

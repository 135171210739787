import React, {useContext, useEffect, useState} from "react";
import "./Footer.css";
import {Link} from "react-router-dom";
import {BlogsContext} from "../../contexts/BlogsContext";
import aggregateProperties from "../../aggregator/PropertiesAggregator";


const Footer = () => {

    const {blogs} : any = useContext(BlogsContext);
    const [categories, setCategories] = useState<any>({});

    useEffect(() => {
        if (blogs instanceof Array) {
            let categoriesInit: any = {}
            blogs.forEach((blog) => {
                let aggregated = aggregateProperties(blog.properties);
                categoriesInit[aggregated.category[0].value] =  categoriesInit[aggregated.category[0].value] || blog.slug;
            });
            setCategories((prevState: any) => {
                return {... categoriesInit};
            });
        }
    }, [blogs]);


    return (
        <footer>
            <div className="footer-top-container">
                <div className="footer-top-categories">
                    <p className="bold">Catégories</p>
                    {Object.keys(categories).map((category) => {
                        return (
                            <Link key={category} to={"/article/" + categories[category]}>
                                <div className="caption-regular">{category}</div>
                            </Link>
                        );
                    })}
                </div>
                <div className="footer-top-navigation">
                    <p className="bold">Navigation</p>
                    <Link to={"/"}>
                        <div className="caption-regular">Mes articles</div>
                    </Link>
                    <Link to={"/bio"}>
                        <div className="caption-regular">Ma bio</div>
                    </Link>
                    <Link to={"/portfolio"}>
                        <div className="caption-regular">Mon portfolio</div>
                    </Link>
                    {/*<Link to={"/grow"}>*/}
                    {/*  <div className="caption-regular">Grow</div>*/}
                    {/*</Link>*/}
                    <Link to={"/contact"}>
                        <div className="caption-regular">Me contacter</div>
                    </Link>
                </div>
            </div>
            <div className="footer-bottom-container">
                <div className="caption-regular">© {new Date().getFullYear()}</div>
                <Link to="/disclaimer">
                    <div className="caption-regular">Mentions légales</div>
                </Link>
            </div>
        </footer>
    );
};

export default Footer;

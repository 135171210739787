import React, {useContext, useEffect, useState} from "react";
import "./Home.css";
import { ReactComponent as ArrowRight } from "assets/img/svg/arrow-right.svg";
import SideCard from "../../components/SideCard/SideCard";
import MiddleCard from "../../components/MiddleCard/MiddleCard";
import { Link } from "react-router-dom";
import Presentation from "../../components/Presentation/Presentation";
import axios from "axios";
import Config from "../../utils/Config";
import aggregateProperties from "../../aggregator/PropertiesAggregator";
import {BlogsContext} from "../../contexts/BlogsContext";

const Home = () => {
  const [numberOfArticlesShown, setNumberOfArticlesShown] = useState(6);
  const {blogs} : any = useContext(BlogsContext);


  useEffect(() => {}, [numberOfArticlesShown]);

  const onClickDisplayMoreArticles = () => {
    setNumberOfArticlesShown(numberOfArticlesShown + 6);
  };

  const listBlogs = () => {
    const articlesByColumn: Array<Array<any>> = [[], [], []];

    //first article is star
    for (let i = 1; i < blogs.length && i < numberOfArticlesShown+1; i++) {
      let article: any = blogs[i];
      const properties = aggregateProperties(article.properties);
      let column = (i - 1) % 3;
      let params = {
        imgSrc: properties.imageHomePreview[0].value,
        imgAlt: properties.imageHomePreview[0].settings.alt,
        title: properties.category[0].value,
        subtitle: properties.title[0].value,
        text: properties.textHomePreview[0].value,
        slug: article.slug,
      };
      articlesByColumn[column].push(
        column != 1 ? <SideCard key={"sideCard"+ article.slug} {...params} /> : <MiddleCard key={"middleCard"+ article.slug} {...params} />
      );
    }

    return (
      <>
        <div className="articles-left-container">{articlesByColumn[0]}</div>
        <div className="articles-center-container">{articlesByColumn[1]}</div>
        <div className="articles-right-container">{articlesByColumn[2]}</div>
      </>
    );
  };

  const hero = () => {
    if (blogs.length > 0) {
      let article: any = blogs[0];
      let properties: any = aggregateProperties(article.properties);
      return (
        <div className="hero">
          <div className="hero-content-container">
            <div className="hero-content">
              <div>
                <div className="line-container">
                  <div className="line" />
                  <h5>{properties.category[0].value}</h5>
                </div>
                <div>
                  <h1>{properties.title[0].value}</h1>
                </div>
              </div>
              <div className="h4-container">
                <p
                  className="regular"
                  dangerouslySetInnerHTML={{ __html: properties.text[0].value }}
                />
                <Link
                  to={`/article/${article.slug}`}
                  state={{
                    article: {
                      label: properties.category[0].value,
                      title: properties.title[0].value,
                      text: properties.text[0].value,
                      imgSrc: properties.image[0].value,
                      imgAlt: properties.image[0].settings.alt,
                      slug: article.slug,
                    },
                  }}
                >
                  <button className="primary">
                    Lire cet article <ArrowRight className="arrow-right" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="hero-image">
            <img
              src={properties.image[0].value}
              alt={properties.image[0].settings.alt}
              height={630}
            />
          </div>
        </div>
      );
    } else {
      return <div className="hero">Chargement ...</div>;
    }
  };

  return (
    <>
      <div className="container">
        {hero()}

        <div className="articles-container">{listBlogs()}</div>

        <div className="articles-container-mobile">{listBlogs()}</div>

        {/*<div className="articles-container-mobile">*/}
        {/*  */}
        {/*</div>*/}
        {/* + 1 because first article is star */}
        {blogs.length > numberOfArticlesShown+1 && (
          <div className="more-articles-button-container">
            <button
              className="primary-small"
              onClick={onClickDisplayMoreArticles}
            >
              Plus d'articles
            </button>
          </div>
        )}
      </div>
      <Presentation />
    </>
  );
};

export default Home;

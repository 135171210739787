import React, { useMemo } from 'react'
import {Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom'

import IMGSEO from 'assets/img/logo/logo.png'

// ///////////////////////
// SECTION: TYPES
export interface IHelpersSEOProps {
  title?: string | null
  titleValues?: {
    [key: string]: unknown
  }

  seoTitle?: string
  seoDescription?: string
  seoBanner?: string

  twitterCard?: 'summary' | 'summary_large_image'
}

// ///////////////////////
// SECTION: EXPORTS
const SEO: React.FC<IHelpersSEOProps> = ({
  title,
  titleValues,
  seoTitle,
  seoDescription,
  seoBanner,
  twitterCard = 'summary_large_image',
}) => {
  const location = useLocation()


  //  ///////////////////////
  //  SECTION: SEO
  const websiteTitle = "Translatologic"

  // Update when pathname or search changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const url = useMemo<string>(() => window.location.href, [location.pathname, location.search])

  const titleString = title;


  const seoBannerURL = useMemo<string>(() => `${window.location.origin}${seoBanner ?? IMGSEO}`, [
    seoBanner,
  ])

  //  ///////////////////////
  //  SECTION: RETURN
  return (
    <Helmet defer={false}>
      <base target='_blank' href={`${window.location.origin}/`} />

      {/* 
        SECTION: Title
      */}
      <meta name='title' content={seoTitle} />
      <meta name='author' content={websiteTitle} />
      <meta name='description' content={seoDescription} />

      {/* 
        SECTION: Google / Search Engine Tags
      */}
      <meta itemProp='name' content={seoTitle} />
      <meta itemProp='description' content={seoDescription} />
      <meta itemProp='image' content={seoBannerURL} />

      {/* 
        SECTION: Open Graph
      */}
      <meta property='og:type' content='website' />
      <meta property='og:url' content={url} />
      <meta property='og:title' content={seoTitle} />
      <meta property='og:description' content={seoDescription} />
      <meta property='og:image' content={seoBannerURL} />

      {/* 
        SECTION: Twitter
      */}
      <meta property='twitter:card' content={twitterCard} />
      <meta property='twitter:url' content={url} />
      <meta property='twitter:title' content={seoTitle } />
      <meta property='twitter:description' content={seoDescription } />
      <meta property='twitter:image' content={seoBannerURL} />
    </Helmet>
  )
}

export default SEO
